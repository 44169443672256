import imagesLoaded from 'imagesloaded';

/**
 * @returns {boolean}
 */
export function exists(el) {
    return document.querySelectorAll(el).length;
}

/**
 * 
 * @param {callable} callback 
 * @returns {void}
 */
export const domReady = (callback) => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callback);
    } else {
        callback();
    }
}

/**
 * @returns {string}
 */
export function getFilename(url) {
    return url
        .replace(/\\/g, "/")
        .replace(/.*\//, "")
        .split(/[?#]/)[0];
}

/**
 * Convert URL params into object
 * @returns {object}
 */
export function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, 
        (m, key, value) => vars[key] = value );
    return vars;
}

/**
 * @returns {boolean}
 */
export function isFunction(item) {
    return typeof item === "function";
}

/**
 * @returns {boolean}
 */
export function isArray(item) {
    return Array.isArray(item);
}

/**
 * Values helper
 *
 * Retrieve values from [this.items] when it is an array, object or Collection
 *
 * @returns {*}
 * @param items
 */
export function values(items) {
    const valuesArray = [];

    if (Array.isArray(items)) {
        valuesArray.push(...items);
    } else if (items.constructor.name === "Collection") {
        valuesArray.push(...items.all());
    } else {
        Object.keys(items).forEach((prop) => valuesArray.push(items[prop]));
    }

    return valuesArray;
}

/**
 * @returns {boolean}
 */
export function isObject(item) {
    return (
        typeof item === "object" &&
        Array.isArray(item) === false &&
        item !== null
    );
}

export function imgURL(src, size) {
    // remove any current image size then add the new image size
    return src
        .replace(
            /_(pico|icon|thumb|small|compact|medium|large|grande|original|1024x1024|2048x2048|master)+\./g,
            "."
        )
        .replace(/\.jpg|\.png|\.gif|\.jpeg/g, function(match) {
            return "_" + size + match;
        });
}

export function formatMoney(cents, format) {
    let money_format = "${{amount}}";
    if (typeof cents == "string") {
        cents = cents.replace(".", "");
    }
    var value = "";
    var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    var formatString = format || money_format;

    function defaultOption(opt, def) {
        return typeof opt == "undefined" ? def : opt;
    }

    function formatWithDelimiters(number, precision, thousands, decimal) {
        precision = defaultOption(precision, 2);
        thousands = defaultOption(thousands, ",");
        decimal = defaultOption(decimal, ".");

        if (isNaN(number) || number == null) {
            return 0;
        }

        number = (number / 100.0).toFixed(precision);

        var parts = number.split("."),
            dollars = parts[0].replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1" + thousands
            ),
            cents = parts[1] ? decimal + parts[1] : "";

        return dollars + cents;
    }

    switch (formatString.match(placeholderRegex)[1]) {
        case "amount":
            value = formatWithDelimiters(cents, 2);
            break;
        case "amount_no_decimals":
            value = formatWithDelimiters(cents, 0);
            break;
        case "amount_with_comma_separator":
            value = formatWithDelimiters(cents, 2, ".", ",");
            break;
        case "amount_no_decimals_with_comma_separator":
            value = formatWithDelimiters(cents, 0, ".", ",");
            break;
    }

    return formatString.replace(placeholderRegex, value);
}

/**
 * Validate email address
 * @param {string} email 
 * @returns {boolean}
 */
export function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function matchHeights(elem) {
    let nodes = document.querySelectorAll(elem);
    let tallest = Math.max.apply(
        Math,
        Array.from(nodes,(el) => el.clientHeight)
    );
    nodes.forEach((el) => (el.style.height = tallest + "px"));
}

/**
 * Retrive CSS variable value
 * @param {string} variable 
 * @param {string} el 
 * @returns {string}
 */
export function getCSSVar(variable, el = null) {
  el = el ?? document.documentElement; 
  return getComputedStyle(document.documentElement).getPropertyValue(variable);
}

/**
 * Set CSS variable
 * @param {string} variable 
 * @param {string} value 
 * @param {string} el 
 * @returns {undefined}
 */
export function setCSSVar(variable, value, el = null) {
  el = el ?? document.documentElement; 
  return el.style.setProperty(variable, value);
}

/**
 *  Return chunked array
 * @param {array} arr 
 * @param {number} chunkSize 
 * @returns {array}
 */
export function chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));
    return R;
}


export function debounce(func, wait, immediate) {
	let timeout;
	return (...args) => {
		let later = () => {
			timeout = null;
			if (!immediate) func.apply(this, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(this, args);
	};
};

/**
 * Shuffle array in place
 * @param {array} array 
 */
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

/**
 * Handle-ize string
 * @param {String} str 
 * @returns String
 */
export function handleize(str) {
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
      .replace(/\-\-+/g, '-')	// Replaces multiple hyphens by one hyphen
      .replace(/(^-+|-+$)/g, '') // Remove extra hyphens from beginning or end of the string
      .toLowerCase(); // To lowercase
  
    return str
}


export function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

export const preloadImages = (selector = 'img') => {
    return new Promise((resolve) => {
        imagesLoaded(document.querySelectorAll(selector), {background: true}, resolve);
    });
};